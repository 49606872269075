<!--
  退款 弹框
 -->
<template>
  <div class="Cancel">
    <el-dialog :title="title" :visible.sync="Open" class="Open" :before-close="clickCancel" width="667px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="退款原因" prop="reason">
          <el-input v-model="form.reason"></el-input>
        </el-form-item>
      </el-form>
      <div class="Open-operate">
        <div class="cancel" @click="clickCancel">我再想想</div>
        <div class="payment" @click="submitForm('form')">{{ confirm }}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Open: false,
      form: {
        reason: ''
      },
      rules: {
        reason: [
          { required: true, message: '请输入退款原因', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ],
      }
    };
  },
  props: {
    title: {
      type: String,
      default: "退款",
    },
    confirm: {
      type: String,
      default: "确定",
    },
  },
  methods: {
    // 我在想想
    clickCancel() {
      this.$refs.form.resetFields();
      this.Open = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("confirm", this.form.reason);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 30px 40px 30px !important;
}
/deep/ .el-checkbox {
  width: 50%;
  margin-right: 0;
  margin-top: 28px;
}

/deep/ .el-checkbox__label {
  font-size: 16px;
}
/deep/ .el-checkbox__inner {
  width: 17px;
  height: 17px;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fc3737;
}
/deep/ .el-checkbox__inner::after {
  top: 2px;
  left: 6px;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fc3737;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fc3737;
  border-color: #fc3737;
}
/deep/ .el-checkbox__inner:hover {
  border-color: #fc3737;
}
/deep/ .el-dialog__headerbtn {
  z-index: 66;
  top: 43px;
  right: 47px;
  font-size: 18px;
}
/deep/ .el-dialog__title {
  font-size: 24px;
  color: #000000;
  position: relative;
  top: 22px;
  left: 20px;
}
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fc3737;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close {
  color: #fc3737;
}

.Open-operate {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  div {
    width: 120px;
    height: 46px;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 23px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .cancel {
    border: 1px solid #999999;
    color: #999999;
  }
  .payment {
    background: #fc3737;
    color: #ffffff;
    margin-left: 18px;
  }
}
.clear {
  resize: none;
  width: 587px;
  height: 117px;
  background: #f5f5f5;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #e8e8e8;
  margin-top: 32px;
  padding: 15px;
  box-sizing: border-box;
  outline-color: #fc3737;
  &::placeholder {
    color: #999999;
  }
}
.Open-textarea {
  position: relative;
}
.Open-textarea span {
  position: absolute;
  bottom: 18px;
  right: 18px;
}
.Open-orderdesc {
  font-weight: 400;
  color: #999999;
  line-height: 16px;
  font-size: 14px;
  text-align: right;
  margin-top: 28px;
}
</style>
