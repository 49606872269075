<template>
  <el-card class="box-card">
    <!-- 商品 -->
    <div class="contents">
      <div class="head">
        <div class="title">
          需求信息<span>（{{ total }}）</span>
        </div>

        <div class="manage-container">
          <div class="manage-item add_serve" @click="clickPublish">
            <i class="el-icon-circle-plus-outline" style="color: #409eff">发布需求</i>
          </div>
        </div>

      </div>
      <el-empty v-if="showEmpty" :image="require('@/assets/imgs/no_hall.png')" description="你还未发布需求"></el-empty>
      <div class="main" v-show="!showEmpty">
        <div class="sort-container">
          <el-button size="mini" plain disabled>
            最新需求<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </div>

        <div class="table-header">
          <div class="column-item title">需求标题</div>
          <div class="column-item provider">服务商</div>
          <div class="column-item mode">交易模式</div>
          <div class="column-item number">交易金额</div>
          <div class="column-item status">任务状态</div>
          <div class="column-item option">操作</div>
        </div>
        <div class="table-body">
          <div class="table-row" v-for="item in renderList" :key="item.id">
            <div class="publish-time">发布时间：{{ item.publishTime }}</div>
            <div class="column-item title" @click="clickDetail(item)">
              <el-avatar icon="el-icon-user-solid" shape="square"
                         :size="50" :src="item.img"></el-avatar>
              <span class="text">{{ item.title }}</span>
            </div>
            <div class="column-item provider" @click="clickChat(item)">
              <el-avatar icon="el-icon-user-solid"
                         v-if="item.grabUser"
                         :size="50" :src="item.grabUser.avatarImg"></el-avatar>
              <span class="text" v-if="item.grabUser">{{ item.grabUser.nickName }}</span>
            </div>
            <div class="column-item mode">{{ item.mode ? '计时悬赏' : ''}}</div>
            <div class="column-item number">￥{{ item.amount }}</div>
            <div class="column-item state" :class="item.stateClass">{{ item.state ? item.state : '' }}</div>
            <div class="column-item option">
              <el-button size="small" v-if="item.state === NEED_STATE.WAITING_PAY.label" @click="clickPay(item)">支付</el-button>
              <el-button size="small" v-if="item.state === NEED_STATE.WAITING_PAY.label" @click="clickEdit(item)">加价</el-button>
              <el-button size="small" v-if="item.state === NEED_STATE.GRABBING_ORDER.label" @click="showRefundsDialog(item)">取消</el-button>
              <el-button size="small" v-if="item.state === NEED_STATE.WAITING_EMPLOY.label" @click="clickDetail(item)">雇用</el-button>
              <el-button size="small" v-if="item.state === NEED_STATE.WAITING_CHECK.label" @click="clickCheck(item)">验收</el-button>
              <el-button size="small" v-if="item.state === NEED_STATE.CHECK_PASS.label" @click="clickEvaluate(item)">评价</el-button>
            </div>
          </div>
        </div>
        <Pagination :total="total" :size="queryParam.pageSize" :page="queryParam.pageNo" @handlePage="PageChange" v-if="total > queryParam.pageSize" />
      </div>

    </div>
    <!-- 结束工作 -->
    <Work ref="Work" :title="workDialog.title" :WorkDesc="workDialog.desc" @handleWork="confirmCheck" />
    <!-- 评价 -->
    <Evaluate ref="Evaluate" @success="getList" />
    <refunds ref="refunds" :title="refunds.title" :confirm="refunds.confirm" @confirm="confirmRefunds" />
  </el-card>
  
</template>

<script>
import Pagination from "@/components/Pagination/index.vue";
import moment from "moment";
import Evaluate from "@/views/Seeking/evaluate.vue";
import Work from "@/views/Order/Work.vue";
import { REQUIREMENT_STATE } from "@/constants/requirement";
import { mapActions } from "vuex";
import refunds from "@/views/Order/refunds.vue";
export default {

  data() {
    return {
      list: [],
      showEmpty: false,
      total: 0,
      queryParam: {
        publishUserId: this.$store.getters.getUser.id,
        pageNo: 1,
        pageSize: 10,
        createDesc: true
      },
      curItem:    '',
      workDialog: {
        title: '',
        desc: ''
      },
      NEED_STATE: REQUIREMENT_STATE,
      refunds:    {
        title: '取消并退款',
        confirm: '确定'
      }
    };
  },
  components: {
    refunds,
    Work, Evaluate,
    Pagination
  },
  created() {
    this.id = this.$store.getters.getUser.id;
    this.getList();
  },
  mounted() {

  },
  computed: {
    renderList() {
      let retList = this.list
      retList.forEach(item => {
        item.publishTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
        item.mode = item.type === '1' ? '计时悬赏' : '正常交易'
        let key = Object.keys(this.NEED_STATE).find(key => +this.NEED_STATE[key].value === item.requirementState)
        if (key) {
          item.state = this.NEED_STATE[key].label
          item.stateClass = this.NEED_STATE[key].cssClass
        }
        if (item.filePath) {
          let imageList = item.filePath.split(',')
          if (imageList && imageList.length) item.img = imageList[0]
        }
      })
      return retList;
    }
  },
  methods: {
    moment,
    ...mapActions(['showChat']),
    // 获取用户发布需求
    getList() {
      let url = `/app-api/hiring/requirement/page`;

      this.$axios
          .get(url, {params: this.queryParam})
          .then((res) => {
            if (res.data.code === 0) {
              this.list = res.data.data.list;
              this.total = res.data.data.total
              this.showEmpty = !this.list || !this.list.length
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    PageChange(val) {
      this.queryParam.pageNo = val;
      this.getList();
    },

    // 发布
    clickPublish() {
      this.$router.push({
        name: 'SeekingHallPublish',
      })
    },
    clickEdit(item) {
      this.$prompt('请输入金额', '加价', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(\d+)((?:\.\d+)?)$/,
        inputErrorMessage: '请输入大于零的有效数字',
        closeOnClickModal: false
      }).then(({ value }) => {
        this.doChangePrice(item, value)
      }).catch(() => {
      });
    },
    doChangePrice(item, value) {
      let url = `/app-api/hiring/requirement/update-price`;
      this.$axios
          .post(url, {
            id: item.id,
            price: value
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("加价成功！");
              this.getList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    clickDetail(item) {
      this.$router.push({
        name: 'SeekingHallDetail',
        query: {
          id: item.id
        }
      })
    },
    clickChat(item) {
      if (item.orderUserPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: item.orderUserPhone,
        goods: {
          id: '',
          name: '',
          desc: '',
          img: ''
        }
      });
    },
    clickDelete(item) {
      this.$confirm("确定删除所选需求吗？")
          .then(() => {
            this.$axios
                .delete(`/app-api/hiring/requirement/delete`, {
                  params: {
                    id: item.id
                  }
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    this.$message.success('删除成功!');
                    setTimeout(() => {
                      location.reload();
                    }, 500);
                  }
                })
                // .catch((err) => {
                  // this.$message.error("删除失败！" + err)
                // });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
    },
    // 未支付订单点击支付
    clickPay(item) {
      this.$router.push({
        name: "DemandPay",
        query: {
          id: item.id,
        },
      });
    },
    // 点击验收按钮，弹出验收确认框
    clickCheck(item) {
      this.workDialog = {
        title: '是否验收工作',
        desc: '确认验收',
      }
      this.curItem = item
      this.$refs.Work.Open = true;
    },
    // 确认验收
    confirmCheck() {
      this.$axios
          .post(`/app-api/hiring/requirement-order/check-accept`, {
            id: this.curItem.order.id
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("验收完成！")
              this.$refs.Work.Open = false;
              this.getList()
            }
          })
          .catch(() => {
            // this.$notify.error('验收失败，请稍后再试')
            this.$refs.Work.Open = false;
            setTimeout(() => this.getList(), 500)
          })
    },
    // 点击评价
    clickEvaluate(item) {
      this.$refs.Evaluate.Open = true;
      this.$refs.Evaluate.Order = item;
    },
    // 退款
    showRefundsDialog(item) {
      if (item.state === REQUIREMENT_STATE.GRABBING_ORDER.value) {
        this.refunds.title = "是否取消";
        this.refunds.confirm = "确认取消";
      }
      this.$refs.refunds.Open = true;
      this.curItem = item;
    },
    confirmRefunds(reason) {
      let url = `/app-api/hiring/requirement-order/refund`;
      this.$axios
          .post(url, {
            orderNo: this.curItem.orderNo,
            reason: reason
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("取消成功！退款稍后将返回原账户。");
              this.$refs.refunds.Open = false;
              this.getOrderList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
  },
};

</script>

<style scoped lang="less">
// 商品
.contents {
  padding: 10px;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    
    .add_serve {
      cursor: pointer;
      font-size: 18px;
    }
  }

  .main {
    & > div {
      margin: 20px 0;
    }
    .sort-container {
      i {
        transform: rotate(0deg);
        transition: transform .5s;
        &.asc {
          transform: rotate(180deg);
          transition: transform .5s;
        }
      }
    }

    .column-item {
      border: 1px solid #E9E9E9;
      width: 80px;
      color: #999999;
      padding: 0 16px;
      line-height: 30px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
      .text {
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap; //文本不会换行
        margin-left: 5px;
      }
      &.title {
        flex: 1;
        text-align: left;
        
      }
      &.provider {
        width: 120px;
      }
      &.option {
        width: 140px;
        text-align: center;
      }
    }

    .table-header {
      display: flex;
      background-color: #F4F4F4;
      .column-item {
        &:not(:nth-last-child(1)) {
          border-right: 1px solid transparent;
        }
        &:not(:nth-child(1)) {
          border-left: 1px solid transparent;
        }

      }
    }
    .table-body {
      font-size: 14px;
      .table-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & + .table-row {
          margin-top: 20px;
        }

        .column-item {
          line-height: 60px;
          height: 60px;
          &.state {
            color: #666666;
            font-size: 14px;
            &.info {
              color: #999999;
            }
            &.success {
              color: #209F85;
            }
            &.warn {
              color: #ee914e;
            }
            &.danger {
              color: #FF3D3D;
            }
          }
        }
        .column-item + .column-item {
          border-left: 1px solid transparent;
        }

        .title {
          color: #3D8BFF;
          font-size: 16px;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .provider {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .publish-time {
          flex-basis: 100%;
          line-height: 30px;
          padding: 0 20px;
          color: #999999;
          background-color: #F4F4F4;
        }
        .number {
          color: #fc3737;
        }
      }
    }
  }
}
</style>
